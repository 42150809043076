<template>
  <div class=container>
    <table class=table width="709" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">LSD: Real-Time Relighting Algorithm</font></div></td>
          </tr>
          <tr>
            <td ><img src="https://cave.cs.columbia.edu/old/software/lsd/pics/main_image.jpg" ></td>
          </tr>
          <tr>
            <td><p>A novel relighting algorithm has been developed that uses a compact
  representation of a large set of images of the scene that correspond
  to different lighting conditions. Unlike previous relighting
  algorithms, this one exploits not only image correlations over the
  illumination dimensions but also coherences over the spatial
  dimensions of the image. This enables the algorithm to achieve high
  quality relighting in real time. It can render 640x480 images of
  scenes under complex and varying illuminations at 15 frames per second
  using a 2GHz processor. This algorithm was used to develop a Lighting
  Sensitive Display that can render a 3D scene such that it always
  appears to be lit by the real environment that the display resides in.</p>
              <p>The relighting algorithm has been re-implemented and made publicly
    available by Sebastian Enrique and Ravi Ramamoorthi of Columbia's
    Computer Graphics Laboratory. It can be downloaded from <a href="http://www.cs.columbia.edu/cg/relighting/index.html">here</a>.</p></td>
          </tr>
          <tr>
            <td><h3>Related Publications</h3>
                <blockquote>
                <div class="publication" data-v-d81fda88=""><b data-v-d81fda88="">"Lighting Sensitive Display,"<br data-v-d81fda88=""></b><span data-v-d81fda88="">S.K. Nayar, P.N. Belhumeur and T.E. Boult,<br data-v-d81fda88=""></span><span data-v-d81fda88="">Proceedings of ACM SIGGRAPH, Technical Sketch,<br data-v-d81fda88=""></span><span data-v-d81fda88="">Vol. 23, No. 4, pp. 963-979,<br data-v-d81fda88=""></span><span data-v-d81fda88="">Oct. 2004<br data-v-d81fda88=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Nayar_TS04.pdf" target="new" data-v-d81fda88="">PDF</a>] [<a href="reference?bid=169" class="url" data-v-d81fda88="">bib</a>] [<a href="copyright" class="url" data-v-d81fda88="">©</a>] </div>
				<br><br>
               <div class="publication" data-v-d81fda88=""><b data-v-d81fda88="">"Lighting Sensitive Display,"<br data-v-d81fda88=""></b><span data-v-d81fda88="">S.K. Nayar, P.N. Belhumeur and T.E. Boult,<br data-v-d81fda88=""></span><span data-v-d81fda88="">ACM Transactions on Graphics,<br data-v-d81fda88=""></span><span data-v-d81fda88="">Vol. 23, No. 4, pp. 963-979, Oct. 2004<br data-v-d81fda88=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Nayar_TOG04.pdf" target="new" data-v-d81fda88="">PDF</a>] [<a href="reference?bid=183" class="url" data-v-d81fda88="">bib</a>] [<a href="copyright" class="url" data-v-d81fda88="">©</a>] </div>
	</blockquote></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>